import React from "react";
import ReactPlayer from "react-player";
import "./VideosSection.css";

export const VideosSection = () => {
  const handleVideoClick = () => {
    window.location.href =
      "https://diep-calisthenics.myshopify.com/products/push-up-stand-wooden-single-parallel-bars-inverted-stand-practice-push-up-stand";
  };

  return (
    <div className="video-container">
      <div className="video-card" onClick={handleVideoClick}>
        <div className="mobile-image">
          <img src="/assets/images/products-images/paralette-1.jpg" alt="paralette bars"/>
        </div>
        <div className="video-player">
          <ReactPlayer
            url="/assets/videos/paralette.mp4"
            playing
            playbackRate={1}
            muted
            loop
            controls={false}
            width="100%"
            height="100%"
          />
        </div>
        <h3>Push-Up Bars</h3>
      </div>
    </div>
  );
};
