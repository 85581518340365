import React from "react";
import "./Footer.css";
import { FaTiktok } from "react-icons/fa"
import { BsInstagram, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

export const Footer = () => {
  const copyrightYear = new Date().getFullYear();

  return (
    <div className="footer">
      <small> &copy; {copyrightYear} Diep Calisthenics</small>
      <div className="social-links">
        <Link to="https://instagram.com" target="_blank">
          <BsInstagram />
        </Link>
        <Link to="https://www.tiktok.com/@motivationb_11" target="_blank">
          <FaTiktok />
        </Link>
        <Link to="https://www.youtube.com" target="_blank">
          <BsYoutube />
        </Link>
      </div>
    </div>
  );
};
