import { v4 as uuid } from "uuid";
import { formatDate } from "../utils/authUtils";

export const users = [
  {
    _id: uuid(),
    firstName: "Admin",
    lastName: "Admin",
    email: "diep.calisthenics@gmail.com",
    password: "50nicbreeze123",
    createdAt: formatDate(),
    updatedAt: formatDate(),
  },
];
