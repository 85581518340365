import { v4 as uuid } from "uuid";

export const products = [
  {
    _id: uuid(),
    name: "Vanguard Elite",
    original_price: 149,
    discounted_price: 99,
    category_name: "kid",
    is_stock: false,
    rating: 4.3,
    reviews: 109,
    description:
      "These premium leather sneakers embody urban sophistication with their sleek design, high-quality craftsmanship, and attention to detail.",
    trending: true,
    size: 5,
    img: "/assets/images/products-images/image-kids-1.png",
  },
];
