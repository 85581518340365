import "./HeroVideo.css";
import ReactPlayer from "react-player";
import React from "react";

export const HeroVideo = () => {
  const handleShopNowClick = () => {
    window.location.href = "https://diep-calisthenics.myshopify.com/";
  };

  return (
    <div className="hero-video-container">
      <div className="hero-video">
        <ReactPlayer
          url="/assets/videos/abs.mp4"
          playing
          playbackRate={1.3}
          muted
          loop
          controls={false}
          width={"100%"}
          height={"100%"}
        />
      </div>

      <div className="hero-text">
        <h1>Diep Calisthenics</h1>
        <h2>Sculpt Your Body with Diep Calisthenics</h2>
      </div>

      <button onClick={handleShopNowClick} className="shop-now-btn">
        Shop Now
      </button>
    </div>
  );
};
